import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./maxima-telecom.module.scss"
import screenshot from "../../assets/images/projects/maxima-telecom-screen-1.png"
import screenshot2 from "../../assets/images/projects/maxima-telecom-screen-2.png"
import resultsImg from "../../assets/images/projects/maxima-telecom-results.png"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"

const challengeItems = [
  "projects.maxima-telecom.challenge.paragraph-2-list.item-1",
  "projects.maxima-telecom.challenge.paragraph-2-list.item-2",
  "projects.maxima-telecom.challenge.paragraph-2-list.item-3",
  "projects.maxima-telecom.challenge.paragraph-2-list.item-4",
]

const keyResultItems = [
  {
    text: "projects.maxima-telecom.results.paragraph-3-list.item-1-text",
    title: "projects.maxima-telecom.results.paragraph-3-list.item-1-title",
  }, {
    text: "projects.maxima-telecom.results.paragraph-3-list.item-2-text",
    title: "projects.maxima-telecom.results.paragraph-3-list.item-2-title",
  }, {
    text: "projects.maxima-telecom.results.paragraph-3-list.item-3-text",
    title: "projects.maxima-telecom.results.paragraph-3-list.item-3-title",
  }
]

const solutionDescriptionItems = [
  "projects.maxima-telecom.solution-description.paragraph-1-list.item-1",
  "projects.maxima-telecom.solution-description.paragraph-1-list.item-2",
]

const technologyBackgroundList1 = [
  "projects.maxima-telecom.technology-background.paragraph-2-list.item-1",
  "projects.maxima-telecom.technology-background.paragraph-2-list.item-2",
  "projects.maxima-telecom.technology-background.paragraph-2-list.item-3",
  "projects.maxima-telecom.technology-background.paragraph-2-list.item-4",
  "projects.maxima-telecom.technology-background.paragraph-2-list.item-5",
]

const technologyBackgroundList2 = [
  "projects.maxima-telecom.technology-background.paragraph-3-list.item-1",
  "projects.maxima-telecom.technology-background.paragraph-3-list.item-2",
  "projects.maxima-telecom.technology-background.paragraph-3-list.item-3",
  "projects.maxima-telecom.technology-background.paragraph-3-list.item-4",
]

const MaximaTelecom: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()
  const strongTextFormatter = text => (<b className="highlighted-text">{text}</b>)

  const sectionClasses = classNames(
    componentStyles.section,
    componentStyles.container,
    componentStyles.ltrDirection,
    "container"
  )
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
  )
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const numberedListClasses = classNames(
    componentStyles.solutionDescriptionNumberedList,
    "grid-4"
  )
  const challengeListTitleClasses = classNames(
    componentStyles.challengeListTitle,
    componentStyles.sectionText,
  )
  const resultKeyResultListClasses = classNames(
    componentStyles.resultsKeyResultsList,
    "grid-3",
  )
  const projectsTitleClasses = classNames("large-section-title")
  const technologyBackgroundListContainerClasses = classNames(
    componentStyles.technologyBackgroundListContainer,
    "grid-2"
  )
  const technologyBackgroundCubeListClasses = classNames(
    componentStyles.technologyBackgroundCubeList,
    "grid-2"
  )
  const resultsContainerClasses = classNames(
    componentStyles.resultsContainer,
    "grid-2"
  )

  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.maxima-telecom.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.maxima-telecom.seo.title" })}
        pathname={location.pathname}
      />
      <section className={componentStyles.banner}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.maxima-telecom.banner.title",
              })}
            </h1>
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <div className={componentStyles.clientCompany}>
          <h2 className={componentStyles.sectionTitle}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.client-company.title",
            })}
          </h2>
          <div className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.client-company.text",
            })}
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={componentStyles.sectionTitle}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.challenge.title",
            })}
          </h2>
          <p className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.challenge.paragraph-1",
            })}
          </p>
        </div>
        <p className={challengeListTitleClasses}>
          {intl.formatMessage({
            id: "projects.maxima-telecom.challenge.paragraph-2",
          })}
        </p>
        <ol className={numberedListClasses}>
          {challengeItems.map(translatePath => (
            <li key={translatePath} className={componentStyles.challengeNumberedListItem}>
              <p className={componentStyles.challengeNumberedListItemText}>
                {intl.formatMessage({
                  id: translatePath,
                })}
              </p>
            </li>
          ))}
        </ol>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.sectionTitle}>
          {intl.formatMessage({
            id: "projects.maxima-telecom.solution-description.title",
          })}
        </h2>
        <div className={componentStyles.solutionDescription}>
          <div className={componentStyles.solutionDescriptionTextBlock}>
            <p className={componentStyles.sectionText}>
              {intl.formatMessage({
                id: "projects.maxima-telecom.solution-description.paragraph-1",
              }, { b: strongTextFormatter })}
            </p>
            <ul className={componentStyles.solutionDescriptionList}>
              {solutionDescriptionItems.map(translatePath => (
                <li key={translatePath} className={componentStyles.solutionDescriptionListItem}>
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </li>
              ))}
            </ul>
          </div>
          <div className={componentStyles.solutionDescriptionImageContainer}>
            <img
              src={screenshot}
              className={componentStyles.solutionDescriptionImage}
              alt={intl.formatMessage({
                id: "projects.maxima-telecom.solution-description.image-alt",
              })}
              width="452"
              height="335"
            />
            <img
              src={screenshot2}
              className={componentStyles.solutionDescriptionImage}
              alt={intl.formatMessage({
                id: "projects.maxima-telecom.solution-description.image-alt",
              })}
              width="452"
              height="335"
            />
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.sectionTitle}>
          {intl.formatMessage({
            id: "projects.maxima-telecom.technology-background.title",
          })}
        </h2>
        <div className={componentStyles.technologyBackgroundTextBlock}>
          <p className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.technology-background.paragraph-1",
            })}
          </p>
        </div>
        <div className={technologyBackgroundListContainerClasses}>
          <div className={componentStyles.technologyBackgroundList}>
            <p className={componentStyles.technologyBackgroundListTitle}>
              {intl.formatMessage({
                id: "projects.maxima-telecom.technology-background.paragraph-2-title",
              })}
            </p>
            <ul className={technologyBackgroundCubeListClasses}>
              {technologyBackgroundList1.map(translatePath => (
                <li key={translatePath}
                  className={componentStyles.technologyBackgroundCubeListItem}>
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </li>
              ))}
            </ul>
          </div>
          <div className={componentStyles.technologyBackgroundList}>
            <p className={componentStyles.technologyBackgroundListTitle}>
              {intl.formatMessage({
                id: "projects.maxima-telecom.technology-background.paragraph-3-title",
              })}
            </p>
            <ul className={technologyBackgroundCubeListClasses}>
              {technologyBackgroundList2.map(translatePath => (
                <li key={translatePath}
                  className={componentStyles.technologyBackgroundCubeListItem}>
                  {intl.formatMessage({
                    id: translatePath,
                  })}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.sectionTitle}>
          {intl.formatMessage({
            id: "projects.maxima-telecom.results.title",
          })}
        </h2>
        <div className={componentStyles.resultsTextBlock}>
          <p className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.results.paragraph-1",
            })}
          </p>
          <p className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.maxima-telecom.results.paragraph-2",
            })}
          </p>
        </div>
        <div className={resultsContainerClasses}>
          <div className={componentStyles.resultsKeyResults}>
            <div className={componentStyles.resultsKeyResultsTitle}>
              {intl.formatMessage({
                id: "projects.maxima-telecom.results.paragraph-3-title",
              })}
            </div>
            <ul className={resultKeyResultListClasses}>
              {keyResultItems.map(({ text, title }) => (
                <li key={title} className={componentStyles.resultsKeyResultsItem}>
                  <h3 className={componentStyles.resultsKeyResultsItemTitle}>
                    {intl.formatMessage({
                      id: title,
                    })}
                    <p className={componentStyles.resultsKeyResultsItemPercent}>%</p>
                  </h3>
                  <p className={componentStyles.resultsKeyResultsItemText}>
                    {intl.formatMessage({
                      id: text,
                    })}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <img
            src={resultsImg}
            className={componentStyles.resultsImage}
            alt={intl.formatMessage({
              id: "projects.maxima-telecom.results.image-alt",
            })}
            width="600"
            height="380"
          />
        </div>

      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link, textColor }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                textColor={textColor}
                buttonColor="orange"
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout >
  )
}
export default MaximaTelecom
